.App-fix {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
    background-color: white;
    border-radius: 50%;
  }
  .contenedorPagos {
      padding: 20px;
      font-size: 14px;
  }
  .contenedorPagos button {
    margin-top: 20px;
    width: 300px;
    height: 100px;
    font-size: 20px;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header1 {
    background-image:linear-gradient(rgba(255,255,255,.5), rgba(0,0,0,.5)), url("../../../src/concert1.jpg");
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  #testbutton {
    width: 300px;
    height:50px;
    border-radius:180px;
    position:relative;
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    cursor:pointer;
    line-height:12px;
  }
  
  #testbutton:before {
    content:'';
    z-index:1;
    position:absolute;
    display:block;
    width:80%;
    height:70%;
    top:15%;
    left:10%;
    transition: 0.3s opacity ease-in-out;
    filter:blur(15px);
    opacity:0;
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  }
  
  #testbutton:hover:before {
    opacity:1;
    transition: 0.3s opacity ease-in-out;
    filter:blur(25px);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);  
  }
  
  #testbutton:after {
    content:'PAGOS MINOTA!';
    text-align:center;
    line-height:40px;
    font-size:18px;
    color:rgba(235,235,235,1);
    font-weight:bold;
    z-index:5;
    position:absolute;
    display:block;
    border-radius:180px;
    width:92%;
    height:80%;
    top:10%;
    left:4%;  
    background-color:rgb(19, 20, 22);
  }