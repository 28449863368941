.App {
    text-align: center;
  }
  .whattsapp {
    z-index: 99;
  }
  .firstMainPart {
    background-color: #191619;
  }
  .menu-principal {
        position: fixed;
        z-index: 120;
        top: 80px;
        right: 20px;
        border-radius: 75px;
        background-color: #662383;
        
  }
  .menu-principal  .MuiButton-label {
    color: white;
  }
  .MuiMenuItem{
    font-family: 'Barlow Semi Condensed';
    color: red;
  }
  .menu-principal .MuiButtonBase-root {
    min-width: 40px;
    min-height: 40px;
    padding: 0px;
  }