.style-testimonios {
    padding-right: 20px;
    padding-left: 20px;
}
.style-testimonios img { width:100%;
}

.landing-container {
    background-attachment: fixed;
    width: 100%;
    background-blend-mode: normal;
    background-color: #b639dc;
    background-image:url('../../images/landingPage1/fondo\ 1\ CELULAR.jpg');
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    margin: 0;
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}
.landing-container2 {
    background-attachment: fixed;
    width: 100%;
    background-blend-mode: normal;
    background-image:url('../../images/landingPage1/Landing\ Page_AMARILLO\ CELULAR.jpg');
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    margin: 0;
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.titulo-principal {
    padding: 0% 2px 0% 2px;
    font-size: 40px;
    text-align: center;
    LINE-HEIGHT: 42px;
    font-weight: 700;
    color: #fbc920;
    font-family: 'Archivo Black', sans-serif;
    text-shadow: 1px 1px 2px black;
    margin-top: 5%;
    background-size: cover;
}

.titulo-principal2 {
    padding:0% 2px 0% 20px;
    font-size: 60px;
    font-weight: bold;
    color: red;
    font-family: 'Barlow Semi Condensed';
    text-shadow: 1px 1px 1px black;
    margin-top: 5%;
    background-size: cover;
    
}
.subtitulo-principal2 {
    padding:0% 2px 0% 20px;
    font-size: 30px;
    color: blue;
    font-weight: 300;
    font-family: 'Barlow Semi Condensed';
    text-shadow: 1px 1px 1px black;
    margin-top: 5%;
    background-size: cover;

}

.section1 {
    width: 100%;
    padding:0% 15px 0% 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;
}
.section3 {
    width: 100%;
    padding:0% 20px 0% 20px;
    display: block;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;
}
.column {
    max-width:60%;
    display: inline-block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 99;
}
.column1 {
    max-width: 40%;
    display: inline-block;
    z-index: 10;
}
.column1 img {
    height: 414px;
    position: relative;
    left: -90%;
    filter: brightness(0.7);
}
.column2 {
    max-width: 30%;
    display: inline-block;
    z-index: 10;
}
.column2 img {
    height: 414px;
    position: relative;
    left: -28%;
}
.column2Text {
    text-align: left;
    font-size: 24px;
    max-width: 98%;
    display: inline-block;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 99;
    color: black;
}

.column p{
    color: white;
    margin: 3px;
    vertical-align: top;
}
.column p span{
    font-weight: bold;
}

.precioPromo {
    color:#fbc920 !important;
    font-weight: 900;
    font-size: 30px;
    margin-top: 10px;
} 
.precioPromo1 {
    color:#fbc920;
    font-weight: 900;
    font-size: 30px;
    margin-top: 10px;
    -webkit-text-stroke: 2px #fbc920;
}
.precioAntes span{
    text-decoration: line-through;
}
.column .precioAntes{ 
    margin-top: 15px;
}
.container-responsive-lp1{
    display: flex;
    justify-content: center;
    background-image:url('../../images/FondoLogin.png');
}
.container-responsive-auxiliar-lp1 {
    max-width: 420px;
    width: 100%;
}
.column .descuento {
    margin-top: 15px;
    font-size: 47px;
    font-weight: 800;
    color: transparent;
    -webkit-text-stroke: 2px white;
}
.center {
    text-align: center;
    padding-right: 80px;
    font-size: 40px;
}
.column .bigText {
    font-size: 27px;
    margin-top: 15px;
}

.section2 {
    margin: 20px;
    padding-bottom: 20px;
    border: yellow;
    text-align: center;
    border: solid yellow;
    background: #bf12325e;
    border-width: 2px;
}

.section2 .titulo-principal {
    text-shadow: none;
}
.section2 span {
    font-weight: bold;
    font-size: 20px;
    font-family: 'Archivo Black', sans-serif;
}
.row-landing {
    width: 100%;
}
.column30 {
    width: 28%;
    text-align: left;
    min-height: 75px;
    vertical-align: -webkit-baseline-middle;
    display: inline-block;
    padding: 5px;
    border-left: solid yellow;
    color: white;
    font-family: 'Barlow Semi Condensed';
}
.column100 {
    margin-top: 5px;
    text-align: left;
    width: 91%;
    min-height: 45px;
    vertical-align: bottom;
    display: inline-block;
    padding: 6px;
    border-left: solid yellow;
    color: white;
    font-family: 'Barlow Semi Condensed';
}
.mainButton {
    background-image: url("../../images/landingPage1/mainButton.png");
    background-size: cover;
    height:93px;
    margin: 10px;
    margin-bottom: 25px;
    -webkit-filter: drop-shadow(0px 12px 33px yellow);
}
.white {
    color: white;
    font-family: 'Barlow Semi Condensed';
}
.center1 {
    text-align: center;
}
.text-large {
    font-size: x-large;
}
.margin {
    margin: -20px 0px 20px 0px;
}
.bold {
    font-weight: bold;
}
.imagenCelu {
    width: 100%;
}
.blue {
    color: blue;
}
.column1Final {
    display: inline-block;
    width: 70%;
    text-align: right;
    color: white;
    font-size: 26px;
    padding-left: 20px;
    z-index: 99;
    position: relative;
}
.column1Final img {
    width: 50%;
    margin-top: 10%;
    margin-left: 20%;
}
.column2Final {
    display: inline-block;
    width: 20%;
}
.column2Final img{
    position: relative;
    left: -154px;
    width: 400px;
    height: 50%;
    filter: brightness(0.8);
    top: 40px;
}
.marginTop {
    margin-top: -88px;
}
.divisor {
    background-color: yellow;
    position: relative;
    z-index: 99;
    text-align: center;
    width: 100%;
    color: blue;
    margin-top: -18px;
}
.divisorwhite {
    background-color: white;
    position: relative;
    z-index: 99;
    text-align: center;
    width: 100%;
    color: black;
}
.divisorwhite h3 {
    margin-bottom: 0;
}
.containerVideos {
    margin: 20px;
}
.text-principal {
    color: white;
    padding: 20px;
    padding-bottom: 0;
    font-size: 16px;
}
