
.imagen-inicio, .imagen-second,  .imagen-third, .imagen-fourth, .imagen-fiveth  {
    pointer-events: none;
    position: absolute;
    right: auto;
    bottom: 0%;
    z-index: 8; 
    max-height: 80vh; 
}
@media (min-width: 501px) {
  .imagen-second, .imagen-third, .imagen-fiveth  {
    max-height: 80vh;
    left: 0%;
    top: 100px;
  }
  .imagen-inicio {
    max-height: 90vh;
    left: 0%;
    top: 100px;
  }
  .imagen-fourth {
    max-height: 70vh;
    left: 0%;
    top: 120px;
  }
  @keyframes example2 {
    0%	{transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
    40%   {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
    41%  {transform: translate3d(20%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}
    59%  {transform: translate3d(20%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(4deg) skew(0deg, 0deg);}   
    60%  {transform: translate3d(20%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(2deg) skew(0deg, 0deg);}
    61%  {transform: translate3d(20%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(3deg) skew(0deg, 0deg);}
    62%  {transform: translate3d(-150%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(9deg) skew(0deg, 0deg);}
    100% {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
}
}
@media (max-width: 500px) {
  .imagen-inicio,.imagen-second, .imagen-third, .imagen-fourth, .imagen-fiveth  {
    top: 70px;
  }
  .imagen-second { 
    left: -56%;
  }
  .imagen-fiveth  {
    left: -51%;
  }
  @keyframes example2 {
    0%	{transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
    40%   {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
    41%  {transform: translate3d(5%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}
    59%  {transform: translate3d(5%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(4deg) skew(0deg, 0deg);}   
    60%  {transform: translate3d(5%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(2deg) skew(0deg, 0deg);}
    61%  {transform: translate3d(5%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(3deg) skew(0deg, 0deg);}
    62%  {transform: translate3d(-150%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(9deg) skew(0deg, 0deg);}
    100% {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
}
}

.imagen-inicio {
  animation: example 24s infinite;
}
  @keyframes example {
      0%	{transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
      18%   {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
      22%  {transform: translate3d(15%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}
      37%  {transform: translate3d(15%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}
      38%  {transform: translate3d(15%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(2deg) skew(0deg, 0deg);}
      39%  {transform: translate3d(15%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(3deg) skew(0deg, 0deg);}
      41%  {transform: translate3d(-150%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(9deg) skew(0deg, 0deg);}
      100% {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
  }
  
  .imagen-second {
      animation: example1 24s infinite;
  }
  @keyframes example1 {
      0%	{transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}
      18%	{transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}	 
      19%   {transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(2deg) skew(0deg, 0deg);}	  
      20%   {transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(3deg) skew(0deg, 0deg);}
      21%  	{transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
      98%  	{transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
      100% 	{transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}
  }
  .imagen-third {
    animation: example2 24s infinite;
  }
  
  .imagen-fourth {
    animation: example3 24s infinite;
  }
    @keyframes example3 {
        0%	{transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
        60%   {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}
        62%  {transform: translate3d(10%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}
        78%  {transform: translate3d(10%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(4deg) skew(0deg, 0deg);}
        79%  {transform: translate3d(10%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(2deg) skew(0deg, 0deg);}
        80%  {transform: translate3d(10%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(3deg) skew(0deg, 0deg);}
        81%  {transform: translate3d(-150%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(9deg) skew(0deg, 0deg);}
        100% {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
    }
    .imagen-fiveth {
      animation: example4 24s infinite;
    }
      @keyframes example4 {
          0%	{transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
          80%   {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
          82%  {transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(5deg) skew(0deg, 0deg);}
          96%  {transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(4deg) skew(0deg, 0deg);}
          97%  {transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(3deg) skew(0deg, 0deg);}
          98%  {transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(2deg) skew(0deg, 0deg);}
          99%  {transform: translate3d(-150%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(9deg) skew(0deg, 0deg);}
          100% {transform: translate3d(-150%, 0px, 0px) scale3d(1.1, 1.1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg);}
      }
  
  .textWrapper {
      position: relative;
      z-index: 40;
      margin-right: 6%;
      text-align: left;
      margin-top: 85px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      max-width: 1200px;
      margin-left: auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-shadow: 0 0 13px rgba(0, 0, 0, 0.64)
  }
  @media (max-width: 500px) {
    .textWrapper {
      margin: 0 5%;
      margin-top: 50%;
    }
    .hero_h1 {
        font-size: 13vw;
        line-height: 13vw;
        margin-bottom: 0;
        letter-spacing: 1px;
        text-shadow: 0 0 3px white;
    } 
    .hero_marker {
        font-size: 7.1vw;
        line-height: 7vw;
    }  
    .imagen-inicio, .imagen-second, .imagen-third, .imagen-fourth, .imagen-fiveth {
        filter: brightness(40%);
    }  
    .hero_h2 {
      padding: 7px 0px 23px;
    }    
  }
  @media (min-width: 501px) {
    .hero_h1 {
        font-size: 7vw;
        line-height: 6vw;
        margin-bottom: 0;
        letter-spacing: 0;
        text-shadow: 0 0 3px white;
    }
    .hero_marker {
        font-size: 4.1vw;
        line-height: 4vw;
    }  
    .imagen-inicio, .imagen-second {
        filter: unset;
    }  
    .hero_h2 {
      padding: 20px 0px 23px;
      margin-bottom: 20px;
    } 
  }
  .colOther {
      flex:1;
  }
  .hero_h1 {
      font-family: 'Barlow Semi Condensed';
      color: white;
  }
  .hero_h2 {
      max-width: 490px;
      margin-top: 30px;
      font-size: 22px;
      line-height: 36px;
      font-weight: 300;
      font-family: 'Barlow Semi Condensed';
  }
  .white {
      color: white;
  }
  .yellow {
      color: #f8aa00
  }
  .lineDivisor {
      border-top: 1px solid hsla(0, 0%, 100%, 0.19);
      max-width: 570px;
  }
  .hero_marker {
      margin-top: -1.5vw;
      margin-bottom: 1vw;
      -webkit-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
      font-family: 'Permanent Marker', sans-serif;
      color: #0198fc;
      font-weight: 400;
      text-shadow: -2px -3px 10px rgba(0, 0, 0, 0.37);
  }
  .underline-wrapper {
      bottom: -0.4vw;
      width: 20vw;
      height: 2vw;
      padding-top: 0px;
      padding-bottom: 0px;
      -webkit-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
      margin-bottom: 10px;
  }
  
  .backGround {
      position: absolute;
      left: -10%;
      top: 0%;
      right: -10%;
      bottom: 0%;
      z-index: 0;
      background-image: -webkit-gradient(linear, left top, right top, color-stop(5%, rgba(0, 0, 0, 0.94)), color-stop(64%, hsla(0, 0%, 100%, 0))), url(../../images/backGroundConcert.jpg);
      background-position: 0px 0px, 50% 50%;
      background-size: auto, cover;
      background-repeat: repeat, no-repeat;
      opacity: 0.3;
      -webkit-filter: blur(5px);
      filter: blur(5px);
  }
  
  .gradient {
      position: absolute;
      left: 0%;
      top: auto;
      right: 0%;
      bottom: -5%;
      z-index: 20;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 36%;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), color-stop(80%, #191619));
      background-image: linear-gradient(180deg, transparent, #191619 80%);
  }
  .logoKids {
    width: 100px;
    height: 62px;
    filter: brightness(0) invert(1);
    padding-left: 10px;
    border-left: 1px solid #fff;
  }
  .logoCracol {
    width: 62px;
    height: 62px;
    filter: brightness(0) invert(1);
    padding-left: 10px;
  }
  .kidsLegend {
    margin: 0;
    padding-right: 10px;
    color: white;
    font-size: 14px;
    font-weight: 200;
  }
  .containerLogoKids {
    display: flex;
    padding: 15px;
    justify-content: flex-end;
    align-items: center; 
  }
  @media (max-width: 501px) {
    .containerLogoKids {
        margin-bottom: 50px;
    }
    .logoKids {
        width: 80px;
        height: 48px;
    }
    .logoCracol {
        width: 48px;
        height: 48px;
    }
  }
  @media (min-width: 501px) {
    .containerLogoKids {
        margin-bottom: 0px;
    }
  }   
  .hero__h1 {
      margin-top: 0px;
      font-size: 7vw;
      line-height: 7vw;
      letter-spacing: -2px;
  }
  .App-header {
    min-height: 95vh;
    position: relative;
    justify-content: flex-end;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color:  gray;
    background-image: linear-gradient(225deg, #1d171d, #151414);
  }
  
  .App-link {
    color: #3900a8
  }
  .presencial {
    font-size: 29px;
    vertical-align: top;
  }
  .virtual {
    font-size: 42px;
    vertical-align: top;
  }
  @media (max-width: 500px) {
    .virtual {
      font-size: 30px;
      vertical-align: top;
    }
    .presencial {
      font-size: 20px;
    }
  }
  .mes {
    position: absolute;
    top: 20px;
    right: -10px;
  }