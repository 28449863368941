.column1-cp, .column2-cp, .column3-cp, .colum4-cp, .column5-cp {
    width: 20%;
    margin: 5px;
    overflow-x: hidden;
}

.row-cp {
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-color: black;
    border-width: thin;
    background-color: #ffffff85;
}

.container-cp img {
    height: 105px;
    width: 257px;
}

.img-cp {
    text-align: center;
}

.container-cp {
    font-family: 'Barlow Semi Condensed';
    display: flex;
    text-align: center;
    height: 100vh;
    flex-direction: column;
    margin: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-image:url('../../images/FondoLogin.png');
}