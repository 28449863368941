.container-responsive-lp1-felicitaciones{
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    background-color: black;
}
.container-responsive-auxiliar-lp1-felicitaciones {
    max-width: 420px;
    width: 100%;
}
.container-image {
    background-image: url("../../../images/Fondo_Felicitaciones.png");
    background-size: cover;
}
.container {
    height: 100%;
}
.imagen {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 112%;
}
.Titulo {
    font-size: 29px;
    text-align: center;
    color: #fbc920;
    font-family: 'Archivo Black', sans-serif;
    text-shadow: 0px 0px 2px black;
    padding-top: 83px;
}
.Titulo1 {
    font-size: 22px;
    text-align: center;
    color: #fbc920;
    font-family: 'Archivo Black', sans-serif;
    text-shadow: 0px 0px 2px black;
    margin-top: -16px;
}
.Text1 {
    color: #fbc920;
    font-family: 'Barlow Semi Condensed';
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    margin-top: 15px;
}
.container-blanco {
    padding: 11px;
    border-style: solid;
    border-radius: 23px;
    margin: 16px;
    border-color: #ffffff14;
    background-color: #ffffff3b;
    margin-bottom: 15px;
    margin-top: 15px;
}
.fila {
    display: flex;
    flex-direction: row;
    color: white;
    font-family: 'Barlow Semi Condensed';
    font-size: 13px;
    margin-top: -1px;
}
.centro {
    text-align: -webkit-center;
    margin-top: -10px;
}
.negrilla{
    font-family: 'Barlow Semi Condensed';
    font-weight: 1000;
    font-size: 13px;
}
.telefono {
    width: 15px;
}
.sticker {
    width: 35px;
    padding-top: 8px;
}
.texto1 {
    padding-left: 5px;
}
.textoWsp {
    color: white;
    font-family: 'Barlow Semi Condensed';
    font-size: 12px;
    display: flex;
    flex-direction: row;
    margin: 22px;
}
.textoWsp1 {
    color: yellow;
    font-weight: 1000;
    margin-bottom: 20px;
}
.direccion {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    font-family: 'Barlow Semi Condensed';
    font-size: 13px;
    margin-top: 8px;
}
.wspfoto {
    width: 130px;
    margin: 11px;
}
.vertical-center {
    display: flex;
    align-items: center;
}
.direccion1 {
    justify-content: center;
    display: inline-flex;
}
.mapa {
    justify-content: center;
    display: inline-flex;
    height: 114px;
    margin-bottom: 5px;
}
.logoWsp {
    display: flex;
    align-items: center;
}
#root {
   height: 100%; 
}

.textoCentrado {
    display: flex;
    align-items: center;
}