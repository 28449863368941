.container-planes {
    background-color: #662382;
    padding: 20px;
}
.container-planes h1 {
    color: #f6a808;
    font-weight: 700;
}
.container-planes h4 {
    color: white;
}

@media only screen and (max-width: 700px) {
    .column1-planes, .column2-planes, .column3-planes  {
        margin: 10px;
        width: 100%;
    }
    .row-planes {
        display: flex;
        flex-direction: column;
    }
}
@media only screen and (min-width: 701px) {
    .column1-planes, .column2-planes, .column3-planes  {
        margin: 10px;
        width: 33%;
    }
    .row-planes {
        display: flex;
        flex-direction: row;
    }
}

.titulo-amarillo-planes {
    background-color: #f6a808;
    color:#662382;
    padding: 20px 10px 20px 10px;
    font-size: 24px;
    font-weight: 700;
}
.parrafo-planes {
    background-color: whitesmoke;
    margin: 0px 10px 0px 10px;
    padding: 10px;
}
.description-planes {
    background-color: #730564;
    color: white;
    margin: 5px;
}
.description1-color {
    background-color: darkmagenta;
}
.description2-color {
    background-color: #e314e3;
}
.description-planes p {
    margin-bottom: 0;
}
.description-planes .precio {
    font-size: 40px;
}
.precio-mes {
    color:blanchedalmond;
    margin-top: -10px;
}
.vigencia-planes {
    background-color: wheat;
    color: black;
    padding: 3px 10px 3px 10px;
}