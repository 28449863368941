.contenedor-curso {
    height: 100vh;
    width: 100vw;
}

.seccion-logo-minota {
    padding: 10px;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border: 1px solid rgba(0,0,0,.125);
    background: url(../../images/FondoLogin.png)no-repeat;
    background-size:cover;
}

.img-logo-curso { 
    width: 250px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: linear-gradient(90deg, #273b47 25%, #273b47 25%, #273b47 25%);
    -webkit-box-shadow: 9px 3px 24px 5px rgba(0,0,0,0.75); 
    -moz-box-shadow: 9px 3px 24px 5px rgba(0,0,0,0.75); 
    box-shadow: 9px 3px 24px 5px rgba(0,0,0,0.75);
}

.img-logo-curso img{
    padding: 10px;
    max-width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.card-principal-curso {
    margin: 0px;
    border-color: rgb(226, 225, 219);
    max-width: 99%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.fila-inicial {
    display: block;
    justify-content: space-between;
    align-content: center; 
    padding: 5px;
    flex-direction: column;
}

.card-fila-inicial {
    width: 100%;
    height: 107%;
    background: linear-gradient(90deg, #273b47 25%, #273b47 25%, #273b47 25%);
    display: flex;
    justify-content: space-between;
    align-content: center; 
    padding: 5px;
    flex-direction: column;
}

.card-fila-inicial span{
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}

.col-md-8 {
    padding: 0px;
}

.card-estud-seleccionados{
    width: 100%;
    height: 100%;
}

.col {
    width: 100%;
    height: 100%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #273b47 25%, #273b47 25%, #273b47 25%);
}

.text-separador{
    width: 30px;
}

.col span{
    color: white;
    display: flex;
    align-items:flex-end;
    justify-content: center; 
}

.col-md-4 {
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center; 
}

.input-group-text {
    width: auto;
    display: flex;
    border-radius: 13px;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #FDFEFE;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #ced4da;
    background: linear-gradient(90deg, #273b47 25%, #273b47 25%, #273b47 25%);
}

.input-group-text .icon-nombre {
    overflow: hidden;
    text-overflow: ellipsis;
}

.row-curso {
    width: 100%;
    height: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.card-body {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
}

.card-principal-curso {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    align-items: center;
    justify-content: center; 
}

.card-tabla {
    margin-top: 5px;
    display: flex;
    width: 100%;
    overflow-x: hidden;
    height: 447px;
    justify-content: center; 
    align-items: left;
    overflow-y: scroll;
}

.tabla {
    margin-top: 7px;
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center; 
    flex-direction: column;  
}

.tabla-modificada {
    margin-top: 2px;
    width: 90%;
    display: block; 
    align-items: left;
    justify-content: center; 
    box-sizing: border-box;
}

.table-responsive {
    width: 100%;
}

.tabla-modificada h6{
    color: #662383;
    display: flex; 
    align-items: left;
    justify-content: left;
}

table tr:nth-child(1) .input-group-text {
    background: rgb(241, 8, 8);
    background-size:cover;
}

table tr:nth-child(2) .input-group-text {
    background: #48b9b0;
    background-size:cover;
}

table tr:nth-child(3) .input-group-text {
    background: rgb(233, 97, 19);
    background-size:cover;
}

table tr:nth-child(4) .input-group-text {
    background: rgb(44, 124, 230);
    background-size:cover;
}

table tr:nth-child(5) .input-group-text {
    background: rgb(109, 9, 134);
    background-size:cover;
}

table tr:nth-child(6) .input-group-text {
    background: rgb(255 201 7);
    background-size:cover;
}

table tr:nth-child(7) .input-group-text {
    background: rgb(139, 136, 139);
    background-size:cover;
}

table tr:nth-child(8) .input-group-text {
    background:  #273b47;
    background-size:cover;
}


table tr:nth-child(9) .input-group-text {
    background: rgb(241, 8, 8);
    background-size:cover;
}

table tr:nth-child(10) .input-group-text {
    background: #48b9b0;
    background-size:cover;
}

table tr:nth-child(11) .input-group-text {
    background: rgb(233, 97, 19);
    background-size:cover;
}

table tr:nth-child(12) .input-group-text {
    background: rgb(44, 124, 230);
    background-size:cover;
}

table tr:nth-child(13) .input-group-text {
    background: rgb(109, 9, 134);
    background-size:cover;
}

table tr:nth-child(14) .input-group-text {
    background: rgb(255 201 7);
    background-size:cover;
}

table tr:nth-child(15) .input-group-text {
    background: rgb(139, 136, 139);
    background-size:cover;
}

table tr:nth-child(16) .input-group-text {
    background:  #273b47;
    background-size:cover;
}

table tr:nth-child(17) .input-group-text {
    background: rgb(255 201 7);
    background-size:cover;
}

.col-sm-8 {
    margin-top: -8px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.secc-central {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gestion {
    width: 100%;
    padding: 3px;
    margin-top: 5px;
    display: block; 
    align-items: left;
    justify-content: center;
    object-fit: contain;
}

.gestion h6 {
    color: #662383;
    display: flex; 
    align-items: left;
    justify-content: center; 
}

.gestion-card {
    width: 100%;
    padding: 5px;
    position: relative;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.gestion-card img {
    padding: 1px;
    width: 99%;
    display: flex;
    height: 398px;
    opacity: 0.2;
    object-fit: cover;
    position: absolute;
}

.parte-msje {
    width: 100%;
    padding: 3px;
    margin: 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.gestion-curso {
    padding: 5px;
    margin-top: 5px;
    width: 99%;
    height: 384px;
    justify-content: center; 
    overflow-y: scroll;
}

.parte-central {
    width: 100%;
    display: block;
}

.bienvenida {
    width: 100%;
    height: 384px;
    display: flex;
    font-family: 'Barlow Semi Condensed';
    font-size: 40px;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
    scroll-margin-top: initial;
}

.bienvenida span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
    color: rgb(23, 4, 41);
    font-size: 30px;
}
.disabled-input {
    pointer-events:none;
    opacity: 0.5;
}

.bienvenida h2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
    color: rgb(78, 8, 143);
}

.sec-mensaje-unico {
    display: flex;
    justify-content: right;
    justify-items: right;
    flex-direction: column;    
}

.style-fecha {
    display: block;
    text-align: right;
}
.style-mensaje-container {
    display: flex;
    justify-content: right;
}

.style-mensaje {
    display: block;
    text-align: right;
    padding: 10px;
    width: fit-content;
    border-radius: 10px;
    background-color: #b6e5e1;
    padding: 9px;
    -webkit-box-shadow: 9px 3px 24px 5px rgba(235, 152, 203, 0.472);
    -moz-box-shadow: 9px 3px 24px 5px rgba(230, 179, 221, 0.472);
    box-shadow: 9px 3px 24px 5px rgba(230, 170, 227, 0.472);
}

.style-mensaje span {
    border-radius: 10px;
    background-color: #b6e5e1;
    padding: 9px;
    -webkit-box-shadow: 9px 3px 24px 5px rgba(235, 152, 203, 0.472);
    -moz-box-shadow: 9px 3px 24px 5px rgba(230, 179, 221, 0.472);
    box-shadow: 9px 3px 24px 5px rgba(230, 170, 227, 0.472);
}

.secc-msje {
    width: 95%;
    margin: 15px;
    padding: 8px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-content: center; 
    background: rgb(203, 203, 207);
}

.icons { 
    width: 15%;
    height: 30px;
    flex-direction: column;
    margin-right: -4px;
}

.secc-icons {
    width: 98%;
    height: 30px;
    padding: 10x;
    margin-top: 0px;
    align-content: center;
    display: flex;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
}

.secc-icons button, .boton button {
    border: none;
    margin-left: 10px;
}

.boton button {
    background-color: black !important;
    border-radius: 5px;
}

.icon1 {
    width: 48%;
    height: 30px;
    width: 30px;
    height: 30px;
    display:flex;
    background: url(../../images/Emoticon.png)center;
    background-size: cover;
}

.icon2 {
    width: 30px;
    height: 30px;
    display: flex;
    background: url(../../images/iconEnlace.png)center center no-repeat;
    background-size: cover;
}

.input-text {
    padding: 4px;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    background: rgb(203, 203, 207);
}

#id-form-control {
    @media only screen and (max-width: 1200px) {min-width: 150px;}
    min-width: 300px;
}

.input-text input{
    width: 100%;
}

.input-btn{
    width: 10%;
    height: 30px;
}

.boton{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.boton button{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: url(../../images/iconEnviarmsje.png)center center no-repeat;
    background-size: cover;
}

.footer {
    width: 100%;
    display: block;
    justify-content: center; 
}

.card-footer {
    padding: 2px;
    height: 80px;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    background: url(../../images/FondoAlumnos.png)center center no-repeat;
    background-size: cover;
}

.derechos h6 {
    margin: 0px;
    padding: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.aling-self-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aling-items-center {
    margin-left: 0;
}

