.container-login {
  height: 100vh;
  width: 100%;
  object-fit: contain;
}

.img-fondo-login {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}

.contenedor-login { 
  height: 100vh;
  width: 100vw;
  display:flex;
  align-items: center;
  justify-content: center;
  align-content: center;
 
}

.container-secciones {
  width: 300px;
  height: 480px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  border: 0px solid rgba(0,0,0,.125);
  background: aliceblue;
  -webkit-box-shadow: 3px 3px 24px 5px rgba(0,0,0,0.75); 
  -moz-box-shadow: 3px 3px 24px 5px rgba(0,0,0,0.75); 
  box-shadow: 3px 3px 24px 5px rgba(0,0,0,0.75);
}

.seccion1 {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(90deg, #273b47 25%, #273b47 25%, #273b47 25%);
  border: 1px solid rgba(0,0,0,.125);
}

.img-logo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background: linear-gradient(90deg, #273b47 25%, #273b47 25%, #273b47 25%);
}

.img-logo img {
    max-width: 57%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.seccion2 {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,.125);
}

.container-user {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.entrada1 {
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.form-group {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.seccion3 {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,.125);
}

.container-password {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.entrada2 {
  padding: 10px;
  width: 105%;
  border-radius: 10px;
  display: block;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.form-group1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} 

.seccion4 {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgba(0,0,0,.125);
}

#btn button {
  background: rgb(141, 20, 192) 0%;
  border-radius: 14px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
}

#btn button h6 {
    color: #EBFA07;
    font-size: 18px;
}

.aling-self-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aling-items-center {
  margin-left: 0;
}
